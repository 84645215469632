/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */
window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('@popperjs/core').default;
    window.bootstrap = require('bootstrap');
} catch (e) {

}

// bootstrap bundle
require('./components/bootstrap.bundle.min');

// materialize
require('./components/materialize');

// magnific
require('magnific-popup');

// lazyload
require('lazyload');

// lightbox
require('fslightbox');

// owl
require('./components/owl.carousel.min');

// slick
require('slick-slider');

// phone number format
require('./components/numbermask');

// front
require('./components/front');


