"use strict";
// csrf token
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
// lazyload
$("img.lazyload").lazyload();
// numbermask
$('.phone').simpleMask( { 'mask': '(###) ###-##-##' } );
// preloader
// $(window).on('load', function () {
//     $('.perloader').fadeOut();
//     $('perloader-in').delay(150).fadeOut('slow');
// });
// preloader
$(".preloader").delay(300).animate({"opacity": "0"
}, 800, function () {
    $(".preloader").css("display", "none");
});
// search box
$('.searchbtn').on('click', function() {
    $('.search-area').toggleClass('open');
});
$('.close-searchbox').on('click', function() {
    $('.search-area').removeClass('open');
});
// popup
$('.popup-youtube, .popup-vimeo').magnificPopup({
    // disableOn: 700,
    type: 'iframe',
    // mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: true,
    fixedContentPos: false
});
// main slider
$('.slider').slider({
    animate: "slow",
    full_width: false,
    interval: 5500,
    transition: 2000,
    draggable: false,
});
// blog slider 
$(".blog-slider-one").owlCarousel({
    nav: false,
    dots: true,
    loop: true,
    navText: ['<i class="ph ph-caret-left"></i>', '<i class="ph ph-caret-right"></i>'],
    margin: 25,
    items: 1,
    thumbs: false,
    smartSpeed: 500,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplayHoverPause: false,
    responsiveClass: true,
    autoHeight: true,
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1200: {
            items: 3,
        }
    }
});
// team slider
$(".team-slider-one").owlCarousel({
    nav: false,
    dots: true,
    loop: true,
    margin: 25,
    items: 1,
    thumbs: false,
    smartSpeed: 500,
    autoplay: true,
    autoplayTimeout: 6500,
    autoplayHoverPause: false,
    responsiveClass: true,
    autoHeight: true,
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1200: {
            items: 3,
        },
        1400: {
            items: 4,
        }
    }
});
// partner slider 
$(".partner-slider-one").owlCarousel({
    nav: false,
    dots: false,
    loop: true,
    margin: 20,
    items: 1,
    thumbs: false,
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: false,
    responsiveClass: true,
    autoHeight: true,
    responsive: {
        0: {
            items: 2,
        },
        768: {
            items: 4,
        },
        992: {
            items: 6,
        },
        1200: {
            items: 7,
        },
        1400: {
            items: 8,
        }
    }
});
// sticky header
var wind = $(window);
var sticky = $('.header-wrap');
wind.on('scroll', function () {
    var scroll = wind.scrollTop();
    if (scroll < 100) {
        sticky.removeClass('sticky');
        $('.logo-light').addClass('d-none')
        $('.logo-dark').removeClass('d-none')
    } else {
        sticky.addClass('sticky');
        $('.logo-light').removeClass('d-none')
        $('.logo-dark').addClass('d-none')
    }
});
// responsive mmenu
$(window).on('resize', function() {
    if($(window).width() <= 1199) {
        $('.collapse.navbar-collapse').removeClass('collapse');
    }else{
        $('.navbar-collapse').addClass('collapse');
    }
});
$('.mobile-menu a').on('click', function() {
    $('.main-menu-wrap').addClass('open');
    $('.collapse.navbar-collapse').removeClass('collapse');
});
$('.mobile_menu a').on('click', function () {
    $(this).parent().toggleClass('open');
    $('.main-menu-wrap').toggleClass('open');
});
$('.menu-close').on('click', function () {
    $('.main-menu-wrap').removeClass('open')
});
$('.mobile-top-bar').on('click', function () {
    $('.header-top').addClass('open')
});
$('.close-header-top button').on('click', function () {
    $('.header-top').removeClass('open')
});
var $offcanvasNav = $('.navbar-nav'),
$offcanvasNavSubMenu = $offcanvasNav.find('.dropdown-menu');
$offcanvasNavSubMenu.parent().prepend('<span class="menu-expand"><i class="ph ph-caret-down"></i></span>');
$offcanvasNavSubMenu.slideUp();
$offcanvasNav.on('click', 'li a, li .menu-expand', function (e) {
    var $this = $(this);
    if (($this.attr('href') === '#' || $this.hasClass('menu-expand'))) {
        e.preventDefault();
        if ($this.siblings('ul:visible').length) {
            $this.siblings('ul').slideUp('slow');
        } else {
            $this.closest('li').siblings('li').find('ul:visible').slideUp('slow');
            $this.siblings('ul').slideDown('slow');
        }
    }
    if ($this.is('a') || $this.is('span') || $this.attr('class').match(/\b(menu-expand)\b/)) {
        $this.parent().toggleClass('menu-open');
    } else if ($this.is('li') && $this.attr('class').match(/\b('dropdown-menu')\b/)) {
        $this.toggleClass('menu-open');
    }
});
// back To top
function BackToTop() {
    $('.back-to-top').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 0);
        return false;
    });
    $(document).scroll(function () {
        var y = $(this).scrollTop();
        if (y > 600) {
            $('.back-to-top').fadeIn();
            $('.back-to-top').addClass('open');
        } else {
            $('.back-to-top').fadeOut();
            $('.back-to-top').removeClass('open');
        }
    });
}
BackToTop();